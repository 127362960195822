
/**************************
*
*	GENERAL
*
**************************/
.camera_wrap img, 
.camera_wrap ol, .camera_wrap ul, .camera_wrap li,
.camera_wrap table, .camera_wrap tbody, .camera_wrap tfoot, .camera_wrap thead, .camera_wrap tr, .camera_wrap th, .camera_wrap td
.camera_thumbs_wrap a, .camera_thumbs_wrap img, 
.camera_thumbs_wrap ol, .camera_thumbs_wrap ul, .camera_thumbs_wrap li,
.camera_thumbs_wrap table, .camera_thumbs_wrap tbody, .camera_thumbs_wrap tfoot, .camera_thumbs_wrap thead, .camera_thumbs_wrap tr, .camera_thumbs_wrap th, .camera_thumbs_wrap td {
	background: none;
	border: 0;
	font: inherit;
	font-size: 100%;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	list-style: none;
    /* line-height: ; */
}
.camera_wrap {
	display: none;
	float: none;
	position: relative;
	z-index: 1;
	margin: 0 !important;
}
.camera_wrap img {
	max-width: none !important;
    overflow: hidden;
}
.camera_fakehover {
	height: 100%;
	position: relative;
	width: 100%;
	/*z-index: 1;*/
}
.camera_src {
	display: none;
}
.cameraCont, .cameraContents {
	height: 100%;
	position: relative;
	width:100%;
	/*z-index: 1;*/
    display: inline-block;
}
.cameraSlide {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}
.cameraContent {
	bottom: 0;
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.camera_target {
	bottom: 0;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: left;
	top: 0;
	width: 100%;
	z-index: 0;
}
.camera_overlayer {
	bottom: 0;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 0;
}
.camera_target_content {
	bottom: 0;
	left: 0;
	overflow: visible;
	position: absolute;
	right: 0;
	top: 0;
	/*z-index: 2;*/
}
.camera_target_content .camera_link {
	display: block;
	height: 100%;
	text-decoration: none;
}
.camera_loader {
	background:#efede0 url(../img/camera-loader.gif) no-repeat center;
	height: 36px;
    width: 36px;
    top: 50%;
	left: 50%;
	overflow: hidden;
	position: absolute;
	margin: -18px 0 0 -18px;
	z-index: 3;
    border-radius:36px;
}
.camera_loader img {
    border-radius:36px;
}
.camera_clear {
	display: block;
	clear: both;
}
.showIt {
	display: none;
}
.camera_clear {
	clear: both;
	display: block;
	height: 1px;
	margin: 0px;
	position: relative;
}
.slider{
	position: relative;
    z-index:0;
    top: 0;
    margin-bottom:59px;
}
.camera_pag {
	position: absolute;
	z-index: 10;
	bottom:15px;
    width: 100%;
	padding:0;
	display:inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
    left:0;
}
.camera-caption {
	position: absolute;
	bottom:-28px;
    width:100%;
	display: block;
    padding:0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.camera-caption .title {
    font:bold italic 20px/90px 'Georgia';
    color:#fff;
    margin-bottom:0;
    width:433px;
    height:90px;
    position:relative;
    background:#f8941d;
    text-align:center;
    float:left;
}
.camera-caption a {
    /* background:#f84f1d url(../img/camera_arrow.png) center center no-repeat; */
    width:90px;
    height:90px;
    display:inline-block;
    float:left;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.camera-caption a:hover {
    /* background:#f8941d url(../img/camera_arrow.png) center center no-repeat; */
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.camera_prev span, .camera_next span{
	position: absolute;
	display: block;
	width:86px;
	height:86px;
	top:50%;
    margin-top:-43px;
	cursor: pointer;
	z-index: 1000;
}
.camera_prev span{
	left:0;
	/* background:url(../img/prev.png) 0 top no-repeat; */
}
.camera_prev span:hover{
    /* background:url(../img/prev.png) 0 bottom no-repeat; */
}
.camera_next span{
	right:0;
	/* background:url(../img/next.png) 0 top no-repeat; */
}
.camera_next span:hover{
    /* background:url(../img/next.png) 0 bottom no-repeat; */
}
.camera_pag_ul {
    display: inline-block;
}
.camera_pag_ul li{
	text-indent: -100px;
    float:left;
    margin:0 0 0 4px;
}
.camera_pag_ul li:first-child {
    margin-left:0;
}
.camera_pag_ul li>span{
	cursor: pointer;
	display: block;
	width:13px;
	height:13px;
    border-radius:100%;
    background:none;
	text-indent: -100px;
	overflow: hidden;
    background:#fff;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.camera_pag_ul li>span>span{
    display:none;
}
.camera_pag_ul li.cameracurrent span, .camera_pag_ul li:hover span{
    background:#333;
    -webkit-transition: all 0.25s ease; 
    transition: all 0.25s ease;
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 1220px) {
    
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 992px) and (max-width: 1219px){
    
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 768px) and (max-width: 991px) {
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
.camera-caption {
    bottom:0;
}
.camera-caption .title {
    line-height:50px;
    height: 50px;
    width:100%;
    font-weight:normal;
    font-size:12px;
}
.camera-caption a {
    position: absolute;
    right: 0;
    height: 50px;
    width: 50px;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 626px) {
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 560px) {
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 480px) {
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 320px) {
}
