@import url(//fonts.googleapis.com/css?family=Roboto+Slab:400,300,100,700);
@import url(//fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
@import url(//fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(//fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap);

/* #a60000 */
body {
	background: #ffffff;
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 1.428571429;
	color: #666666;
}
a {
	text-decoration: none;
}
.mz-3{
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.testimonial_minHeight{
	min-height: 465px;
}
.elementToFadeInAndOut {
    -webkit-animation: fadeinout 10s linear forwards;
    animation: fadeinout 10s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}
a:hover {
	text-decoration: none;
}
a:focus {
	text-decoration: none;
	background: none;
}
a[href^="tel:"] {
 color: inherit;
 text-decoration: none;
}
.menu_heading_combo{
	color:#342a27;
	/* font: bold 56px / 56px ''; */
	font-size: 3.5vw;
	font-family: '';
	font-weight: bold;
}
.topbar_text .title{
	font: 18px/20px '';
	color: #303030;
	margin: 0;
}
.forcombo{
	background-color: #f6e4c2;
	/* background: url(../img/28313.jpg); */
	background-repeat: no-repeat;
    background-size: 100% 100%;
	padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)*0.5);
}
.forcombo .caption{
	background-color: #fff;
	padding-left: 3%;
	padding-right: 3%;
}
.forcombo .description{
	margin: 0;
	font: 18px / 24px '';
    color: #303030;
	text-align: justify;
	
}

.events .caption{
	/* background-color: #fff; */
	/* border:1px solid #303030; */
	padding-left: 5%;
	padding-right: 5%;
	background-color: #f2f2f2;
}
.topbar_text{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	border-bottom: 1px solid #342a27;
	padding-bottom: 0.5%;

}
.events .description{
	margin: 0;
	font: 18px / 24px '';
    color: #303030;
	text-align: justify;
	
}
.bookingForm {
    position: relative;
    display: block;
    font: 18px / 56px '';
    color: #2d2d2d;
}
.form-box .title {
    font: 22px / 24px '';
    color: #e6a453;
    margin-bottom: 5%;
}
.gallery_back{
	background-color: #e6a453;
}
.event_block .title {
	font-weight: 700;
	font-size: 1.5rem;
    /* font: 22px / 24px ''; */
    color: #303030;
    margin-bottom: 5%;
	font-family: 'ubuntu-regular';
}
.event_block{
	text-align: left;
}
.event_block .description{
	text-align: justify;
}
.tmInput{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}
.input_style{
	width: 100%;
	/* font: 18px / 56px ''; */
	font-size: 1rem;
    line-height: 3rem;
    font-family: 'ubuntu-regular';
	color: #e6a453;
	border-bottom: 1px solid #e6a453;
	outline: #fff;
	border-top: 0px solid;
	border-left: 0px solid;
	border-right: 0px solid;
	background-color: transparent;

}
.navbar_logo_color{
	color: #a60000 !important;
}
.footer_dark{
	background-color: #303030;
}
.footer_dark h6{
	font: 18px / 56px '';
	color:#fff
}
textarea:focus, input:focus{
    border-bottom: 1px solid #303030 !important;
}
textarea, input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
	-webkit-appearance: none;
	-moz-appearance: caret;
}
p {
	margin-bottom: 22px;
	font-weight: 300;
	line-height: 22px;
}
p span {
	color: #2d2d2d;
	font-size: 16px;
	line-height: 25px;
}
h1.navbar-brand_ {
	margin: 0;
	padding: 0;
	float: left;
}
h1.navbar-brand_ a {
	display: inline-block;
	padding: 0;
}
h2  {
	font: bold 56px/56px 'Roboto Slab';
	color: #ea4959;
	text-transform: uppercase;
	margin: 0 0 18px 0;
}
.home_header{
	font: bold 56px/56px '';
	color: #ea4959;
	text-transform: uppercase;
	margin: 0 0 18px 0;
}
.menu_heading{
	/* font: bold 56px / 56px ''; */
	font-size: 3.5vw;
	font-family: '';
	font-weight: bold;
    color: #e6a453;
    /* margin: 0 0 49px 0; */
}

.css-4ndf5c-LoadingOverlayWrapperBase{
	display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;
    text-align: center !important;
    margin: 0 0 0 40% !important;
}

h3 {
	font: bold 56px/56px 'Roboto Slab';
	color: #303030;
	margin: 0 0 49px 0;
}
h4 {
	font: 300 16px/25px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #2d2d2d;
	margin: 0 0 5px 0;
}
h4.indent {
	margin: 0 0 15px 0;
}
.extra-wrap {
	overflow: hidden;
}
.global {
	padding: 0;
	background-color: #0d0d0d;
	
}
.container_min_height{
	min-height: 100vh;
}
.global.indent {
	padding: 60px 0 0 0;
}
.center {
	text-align: center;
}
/*-----menu-----*/
nav.tm_navbar {
	background: none;
	border: none;
	padding: 0;
	/* margin: 33px 0 0 0; */
	display: inline-block;
	/* float: right; */
	min-height: 0;
}
nav.tm_navbar li {
	float: left;
	font-size: 15px;
	line-height: 22px;
	padding: 0;
	margin: 0 0 0 37px;
}
nav.tm_navbar li:first-child {
	margin-left: 0;
}
nav.tm_navbar li i {
	position: absolute;
	top: 9px;
	right: -20px;
	width: 5px;
	height: 5px;
	border-radius: 100%;
	/* background: #74c0bc; */
	display: inline-block;
}
nav.tm_navbar li a {
	position: relative;
	padding: 0 2px 7px 2px;
	font: 18px 'Roboto Slab';
	color: #303030;
	background: none;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
	text-decoration: none;
}
nav.tm_navbar li a em {
	width: 0;
	height: 2px;
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #342a27;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar li.active > a {
	color: #303030;
	background: none;
}
nav.tm_navbar li.active > a em {
	width: 100%;
}
nav.tm_navbar li:hover > a {
	color: #303030;
	background: none;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar li:hover > a em {
	width: 100%;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar .sfHover > a {
	color: #303030;
	background: none;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar .sfHover > a em {
	width: 100%;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar li span {
	height: 4px;
	/* background: url(../img/menu-marker.png) center 0 no-repeat; */
	width: 100%;
	text-align: center;
	position: absolute;
	left: 0;
	top: 22px;
	z-index: 0;
}
/*-----subMenu1-----*/
nav.tm_navbar .sub-menu > ul {
	position: absolute;
	display: none;
	width: 101px;
	left: -26px;
	top: 42px;
	list-style: none !important;
	zoom: 1;
	z-index: 11;
	background: #ffffff;
	padding: 9px 0 5px 0;
	margin: 0;
	text-align: center;
	border: 1px solid #d6d6d6;
}
nav.tm_navbar .sub-menu > ul li {
	background: none;
	position: relative;
	border: none;
	width: auto;
	float: none;
	margin: 0;
	padding: 0 0 2px 0;
	display: block;
	text-align: center;
	border-bottom: 1px solid #eaeaea;
}
nav.tm_navbar .sub-menu > ul li.tr {
	background: url(../img/tr.png) center 0 no-repeat !important;
	position: absolute;
	top: -10px;
	left: 0;
	width: 100%;
	height: 10px;
	border: none;
}
nav.tm_navbar .sub-menu > ul li:first-child + li + li {
	border-bottom: none;
}
nav.tm_navbar .sub-menu > ul li a {
	background: none;
	padding: 0;
	margin: 0;
	float: none;
	font: 14px 'Roboto Slab';
	color: #4c4c4c;
	text-transform: none;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar .sub-menu > ul li span {
	display: inline-block;
	background: url(../img/menu-marker2.png) right 0 no-repeat;
	width: 6px;
	height: 5px;
	position: absolute;
	left: auto;
	right: 15px;
	top: 10px;
}
nav.tm_navbar .sub-menu > ul li a:hover {
	text-decoration: none;
	color: #74c0bc !important;
	background: none !important;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar .sub-menu > ul li.sfHover > a {
	text-decoration: none;
	color: #74c0bc !important;
	background: none !important;
}
/*-----subMenu2-----*/
nav.tm_navbar .sub-menu ul ul {
	position: absolute;
	display: none;
	width: 101px;
	left: 99px;
	top: -10px;
	list-style: none !important;
	zoom: 1;
	z-index: 11;
	background: #ffffff;
	padding: 9px 0 5px 0;
	margin: 0;
	text-align: center;
	border: 1px solid #d6d6d6;
}
nav.tm_navbar .sub-menu ul ul li {
	border-bottom: 1px solid #eaeaea;
}
nav.tm_navbar .sub-menu ul ul li:last-child {
	border-bottom: none;
}
nav.tm_navbar .sub-menu ul ul li a {
	background: none;
	padding: 0;
	margin: 0;
	float: none;
	font: 14px 'Roboto Slab';
	color: #4c4c4c;
	text-transform: none;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
nav.tm_navbar .sub-menu > ul li > ul li a:hover {
	text-decoration: none;
	color: #74c0bc !important;
	background: none !important;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.select-menu {
	display: none !important;
}
.select-menu {
	display: none !important;
}
/*-----content-----*/
header {
	position: relative;
	margin: 0;
	padding: 1% 0;
	z-index: 11;
	background-color: #f7e8da;
}
header.indent {
	border-bottom: 2px solid #74c0bc;
}
footer {
	padding: 62px 0;
	text-align: center;
}
footer p {
	color: #928e92;
	font: 13px 'Open Sans';
	margin-bottom: 0;
}
footer p a {
	color: #928e92;
	font: 13px 'Open Sans';
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
footer p a:hover {
	color: #ea4959;
}
footer em {
	font-style: normal;
}
footer.indent {
	border-top: 2px solid #74c0bc;
}
#toTop {
	display: none;
	width: 38px;
	height: 38px;
	/* background: url(../img/top.png) 0 top no-repeat; */
	position: fixed;
	margin-right: -660px !important;
	right: 50%;
	bottom: 224px;
	z-index: 999;
}
#toTop:hover {
	/* background: url(../img/top.png) 0 bottom no-repeat; */
}
.trainerBox {
	margin-bottom: 30px;
	position: relative;
	display: block;
}
.trainerBox figure {
	margin: 0;
}
.trainerBox figure a {
	position: relative;
	display: block;
	width: 100%;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.trainerBox figure a:hover {
	opacity: 0.7;
	filter: alpha(opacity=70);
}
.trainerBox figure a:hover img {
	-webkit-transform: rotate(-5deg);
	transform: rotate(-5deg);
}
.trainerBox figure img {
	width: 100%;
	top: 0;
	left: 0;
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
	outline: 1px solid transparent;
	border-radius: 100%;
}
.testimBox {
	padding: 19px 0 0 0;
}
.advantages-box {
	background: #ffffff;
	padding: 0 0 24px 0;
}
.advantages-box .col-lg-3, .advantages-box .col-lg-4, .advantages-box .col-lg-5 {
	margin-bottom: 37px;
}
.project-box {
	background: #ffffff;
	padding: 60px 0 24px 0;
}
.gourmet-box {
	margin-bottom: 60px;
}
.gourmet-box .title {
	font: 22px 'Roboto Slab';
	color: #303030;
	margin-bottom: 35px;
}
.gourmet-box h3 {
	margin-bottom: 15px;
}
.gourmet-box figure img {
	width: 100%;
}
.gourmet-box ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.gourmet-box ul li {
	margin-bottom: 2px;
	overflow: hidden;
}
.gourmet-box ul li strong {
	font-size: 28px;
	color: #74c0bc;
	font-weight: normal;
	margin-right: 21px;
	float: left;
	margin-top: 5px;
}
.gourmet-box ul li h4 {
	margin-bottom: 5px;
}
.who-box {
	background: #ffffff;
	padding: 0;
}
.who-box .col-lg-4 {
	margin-bottom: 24px;
}
.welcome-box p {
	font-size: 16px;
	line-height: 25px;
	color: #e6a453;
	font-family: 'ubuntu-regular';
}
.welcome-box .title {
	font: 22px/24px '';
	color: #303030;
	margin-bottom: 25px;
}
.btn-box {
	margin-top: 66px;
}
.latest-box {
	margin-top: 58px;
	padding-bottom: 22px;
}
.testimonials-box {
	overflow: hidden;
}
.testimonials-box .col-lg-4 {
	margin-bottom: 77px;
}
.testimonials-box .col-lg-4 > .box1 {
	border: 1px solid #ddd;
	padding: 33px 11px 51px 29px;
}
.testimonials-box .col-lg-4 > .box1 figure {
	display: block;
	margin-bottom: 20px;
}
.testimonials-box .col-lg-4 > .box1 p {
	margin-bottom: 0;
	font-size: 16px;
	line-height: 25px;
	color: #2d2d2d;
}
.testimonials-box .col-lg-4 > .box2 {
	border: 1px solid #ddd;
	border-top: none;
	padding: 14px 11px 12px 29px;
	overflow: hidden;
}
.testimonials-box .col-lg-4 > .box2 > div {
	float: left;
}
.testimonials-box .col-lg-4 > .box2 a {
	font: 300 18px/20px 'Roboto Slab';
	color: #2d2d2d;
}
.testimonials-box .col-lg-4 > .box2 a:hover {
	color: #ea4959;
}
.testimonials-box .col-lg-4 > .box2 p {
	color: #2d2d2d;
}
.testimonials-box .col-lg-4 > .box2 figure {
	float: right;
}
.services-box, .dishes-box {
	margin-bottom: 57px;
}
.errorBox {
	padding-bottom: 107px;
}
.errorBox .title {
	font: bold 186px/166px 'Roboto';
	color: #ea4959;
	text-transform: uppercase;
	margin-bottom: 6px;
}
.errorBox .description {
	font: 74px/74px 'Roboto';
	color: #74c0bc;
	text-transform: uppercase;
	margin-bottom: 22px;
}
/*------------------isotop items------------------*/
.isotope, .isotope .isotope-item {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}
.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}
.isotope .isotope-item {
	overflow: visible;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: top, left, opacity;
	transition-property: transform, opacity;
}
/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition, .isotope.no-transition .isotope-item, .isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}
/* End: Recommended Isotope styles */
/* disable CSS transitions for containers with infinite scrolling*/
.isotope.infinite-scrolling {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}
.isotope-box {
	margin-left: -30px;
	margin-bottom: 20px;
}
.element {
	height: auto !important;
	display: block;
	position: relative;
	border: none;
	margin: 0 !important;
	padding: 0 0 0 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.element .thumb-pad1 {
	margin: 0 0 50px 0;
	width: auto !important;
}
.isotope .isotope-item {
	margin: 20px 0 0 0;
}
#options {
	margin-bottom: 47px;
}
#options .pagination {
	margin: 0;
}
#filters li {
	display: inline-block;
	margin-right: 45px !important;
}
#filters {
	position: relative;
}
#filters li {
	float: left;
}
#filters li a {
	padding: 0;
	background: none;
	border: none;
	font: bold 18px 'Roboto Slab';
	color: #303030;
	margin: 0;
	text-decoration: none;
}
#filters li a:hover {
	background: none;
	border: none;
	text-decoration: none;
	color: #ea4959 !important;
}
#filters.pagination li a.selected {
	color: #ea4959 !important;
	background: none !important;
	border: none;
	text-decoration: none;
}
/*-----thumbs-----*/
.thumb-pad00 {
	margin: 0;
	background: #efefef;
}
.thumb-pad00 .thumbnail {
	position: relative;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
}
.thumb-pad00 .thumbnail .caption {
	padding: 0;
	overflow: hidden;
	text-align: left;
}
.thumb-pad00 .thumbnail .caption p {
	margin-bottom: 0;
}
.thumb-pad00 .thumbnail .caption .lnk {
	font: 500 20px/28px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #138c66;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad00 .thumbnail .caption .lnk:hover {
	color: #666666;
}
.thumb-pad00 figure {
	margin: 0 0 19px 0;
}
.thumb-pad00 figure img {
	width: 100%;
}
.thumb-pad00 figure a {
	position: relative;
	display: block;
	overflow: hidden;
}
.thumb-pad00 figure a em {
	position: absolute;
	top: -400px;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	background: rgba(0, 0, 0, 0.7);
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}
.thumb-pad00 figure a em span {
	background: url(../img/zoom.png) center center no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.thumb-pad00 figure a:hover em {
	top: 0;
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}
.thumb-pad1 {
	/* margin: 0 0 55px 1px; */
	/* overflow: hidden;
	/* float: left; */
	/* background: #ea4959;
	width: 389px; */
}
.thumb-pad1.indent {
	margin: 0 0 55px 0;
}
.thumb-pad1 .thumbnail {
	position: relative;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
	overflow: hidden;
}
.ml-5{
	margin-left:5%;
}
.thumb-pad1 .thumbnail .caption {
	padding: 0 20px 17px 20px;
	overflow: hidden;
}
.thumb-pad1 .thumbnail .caption > div {
	float: left;
}
.thumb-pad1 .thumbnail .caption p {
	margin-bottom: 0;
}
.thumb-pad1 .thumbnail .caption .title {
	font: 300 18px/25px 'Roboto Slab';
	color: #ffffff;
	margin-bottom: 0;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.caption{
	background-color: #f8c99e;
	text-align: center;
	padding: 4% 0;
}
.caption .price {
	/* font: bold 29px/29px ''; */
	font-size: 1.5rem;
    font-weight: 700;
    font-family: 'ubuntu-regular';
	color: #020202;
	text-align: center;
	margin-bottom: 0;
}
.menuTitle {
    font: bold 29px / 29px '';
    color: #e6a453;;
    text-align: left;
    margin-bottom: 0;
}
.menuDesc {
    font-size: 16px;
    line-height: 25px;
    color: #e6a453;
    font-family: 'ubuntu-regular';
}
.thumb-pad1 .thumbnail .caption .title:hover {
	color: #ea4959;
}
.thumb-pad1 .thumbnail .caption .description {
	font: 300 16px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 25px;
	color: #ffffff;
	margin-bottom: 0;
}
.thumb-pad1 .thumbnail .caption .price {
	font: bold 29px/29px '';
	color: #ffffff;
	text-align: center;
	/* float: right; */
	/* margin: 8px 0 0 0; */
}
.thumb-pad1 figure {
	margin: 0 0 11px 0;
}
.thumb-pad1 figure img {
	width: 100%;
}
.thumb-pad1 figure a {
	position: relative;
	display: block;
	overflow: hidden;
}
.thumb-pad1 figure a em {
	position: absolute;
	top: -400px;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	background: rgba(116, 192, 188, 0.5);
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}
.thumb-pad1 figure a em span {
	background: url(../img/zoom.png) center center no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.thumb-pad1 figure a:hover em {
	top: 0;
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}
.thumb-pad2 {
	margin: 0 0 50px 0;
}
.thumb-pad2 .thumbnail {
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
}
.thumb-pad2 .thumbnail .caption {
	padding: 0;
	text-align: left;
}
.thumb-pad2 .thumbnail .caption a {
	color: #ea4959;
	text-transform: uppercase;
	font: 300 17px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad2 .thumbnail .caption a:hover {
	color: #2d2d2d;
}
.thumb-pad2 .thumbnail .caption p {
	margin-top: 18px;
}
.thumb-pad2 figure {
	margin: 0 0 23px 0;
}
.thumb-pad2 figure img {
	width: 100%;
}
.thumb-pad3 {
	margin: 0 0 50px 0;
}
.thumb-pad3 .thumbnail {
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
}
.thumb-pad3 .thumbnail .caption {
	padding: 0;
	text-align: left;
}
.thumb-pad3 .thumbnail .caption a {
	color: #ea4959;
	text-transform: uppercase;
	font: 300 17px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad3 .thumbnail .caption a:hover {
	color: #2d2d2d;
}
.thumb-pad3 .thumbnail .caption p {
	margin-top: 18px;
}
.thumb-pad3 figure {
	margin: 0 0 23px 0;
}
.thumb-pad3 figure img {
	width: 100%;
}
.thumb-pad4 {
	margin: 0 0 30px 0;
	overflow: hidden;
	background: #74c0bc;
}
.thumb-pad4 .thumbnail {
	position: relative;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
	overflow: hidden;
}
.thumb-pad4 .thumbnail .caption {
	padding: 0 20px 17px 20px;
	overflow: hidden;
}
.thumb-pad4 .thumbnail .caption p {
	margin-bottom: 0;
}
.thumb-pad4 .thumbnail .caption .title {
	font: 300 18px/20px 'Roboto Slab';
	color: #ffffff;
	margin-bottom: 0;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad4 .thumbnail .caption .title:hover {
	color: #ea4959;
}
.thumb-pad4 figure {
	margin: 0 0 13px 0;
}
.thumb-pad4 figure img {
	width: 100%;
}
.thumb-pad5 {
	margin: 0;
	overflow: hidden;
}
.thumb-pad5 .thumbnail {
	position: relative;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: none;
	padding: 0;
	background: none;
	overflow: hidden;
}
.thumb-pad5 .thumbnail .caption {
	padding: 0;
	overflow: hidden;
}
.thumb-pad5 .thumbnail .caption p {
	margin-bottom: 9px;
	color: #666666;
	letter-spacing: 0;
	font-style: italic;
}
.thumb-pad5 .thumbnail .caption a {
	color: #ea4959;
	text-transform: uppercase;
	font: 300 17px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad5 .thumbnail .caption a:hover {
	color: #2d2d2d;
}
.thumb-pad5 figure {
	margin: 0 29px 0 0;
	float: left;
}
.thumb-pad6 {
	overflow: hidden;
	text-align: center;
}
.thumb-pad6 .thumbnail {
	position: relative;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: none;
}
.thumb-pad6 .thumbnail .caption {
	padding: 0;
	color: #666666;
}
.thumb-pad6 .thumbnail .caption a {
	font: 36px/36px 'Oleo Script';
	color: #45443a;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad6 .thumbnail .caption a:hover {
	color: #ffffff;
}
.thumb-pad6 figure {
	margin: 0;
	border-radius: 100%;
}
.thumb-pad6 figure img {
	border-radius: 100%;
}
.thumb-pad7 .thumbnail {
	position: relative;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: none;
}
.thumb-pad7 .thumbnail .caption {
	padding: 0;
	overflow: hidden;
	color: #666666;
}
.thumb-pad7 figure {
	margin: 0 0 22px 0;
	border-radius: 100%;
	border: 1px solid #f5f5f5;
}
.thumb-pad7 figure img {
	width: 100%;
	border-radius: 100%;
}
.thumb-pad8 {
	overflow: hidden;
	margin-bottom: 57px;
}
.thumb-pad8 .thumbnail {
	position: relative;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: none;
}
.thumb-pad8 .thumbnail a {
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #484848;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.thumb-pad8 .thumbnail a:hover {
	color: #f8941d;
}
.thumb-pad8 .thumbnail p {
	margin-bottom: 15px;
	font-size: 12px;
	line-height: 22px;
}
.thumb-pad8 .thumbnail .caption {
	padding: 0;
	overflow: hidden;
	color: #666666;
}
.thumb-pad8 .thumbnail figure {
	margin: 0 0 10px 0;
}
/*-----btn-----*/
.btn-default.btn1 {
	box-shadow: none;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 27px;
	font-weight: 500;
	font-size: 16px;
	line-height: 40px;
	margin: 0;
	text-shadow: none;
	text-transform: uppercase;
	color: #342a27;
	border-radius: 0;
	background: #f6e4c2;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	border:none
}
.btn-default.btn1:hover {
	color: #342a27;
	background: #f7e8da !important;
	text-decoration: none !important;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
/* .btn-default.btn2 {
	box-shadow: none;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 27px;
	font: 500 16px/65px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin: 0;
	text-shadow: none;
	text-transform: uppercase;
	color: #ffffff;
	border-radius: 0;
	background: #ea4959;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.btn-default.btn2:hover {
	color: #ffffff;
	background: #74c0bc !important;
	text-decoration: none !important;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
} */
.btn-default.btn2 {
	box-shadow: none;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 27px;
	font-weight: 500;
	font-size: 16px;
	line-height: 40px;
	margin: 0;
	text-shadow: none;
	text-transform: uppercase;
	color: #f6e4c2;
	border-radius: 0;
	background: #342a27;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	border:none
}
.btn-default.btn2:hover {
	color: #342a27;
	background: #f7e8da !important;
	text-decoration: none !important;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.btn-default.btn3 {
	box-shadow: none;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 27px;
	font-weight: 500;
	font-size: 16px;
	line-height: 40px;
	margin: 0;
	text-shadow: none;
	text-transform: uppercase;
	color: #ffffff;
	border-radius: 0;
	background: #ea4959;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.btn-default.btn3:hover {
	color: #ffffff;
	background: #74c0bc !important;
	text-decoration: none !important;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}
.btn-default.btn4 {
	box-shadow: none;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 31px;
	font: bold 18px/40px 'Open Sans';
	margin: 0;
	text-shadow: none;
	color: #ffffff;
	border-radius: 0;
	background: #353535;
	border: none;
	text-shadow: 1px 1px 0 #222;
	position: relative;
	top: 26px;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.btn-default.btn4:hover {
	color: #ffffff;
	background: #b04684;
	text-shadow: 1px 1px #a4417b;
	text-decoration: none !important;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.btn-default.btn5 {
	box-shadow: none;
	position: relative;
	text-decoration: none !important;
	padding: 0;
	font: bold 12px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin: 0;
	text-shadow: none;
	color: #f15374;
	background: none;
	display: inline-block;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.btn-default.btn5:hover {
	color: #222;
	background: none;
	text-decoration: none !important;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.btn-default.btn6 {
	box-shadow: none;
	position: relative;
	text-decoration: none !important;
	display: inline-block;
	padding: 0 32px;
	font: 800 14px/43px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	margin: 0;
	text-shadow: none;
	color: #ffffff;
	background: none;
	border: 1px solid #ffffff;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.btn-default.btn6:hover {
	color: #222;
	background: #ffffff;
	text-decoration: none !important;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
/*-----list-----*/
.list1-1 {
	margin: 0 0 16px 0;
	padding: 0;
	list-style: none;
}
.list1-1 li {
	margin-bottom: 6px;
	padding-left: 16px;
	line-height: 22px;
	background: url(../img/list_arrow.png) left 6px no-repeat;
}
.list1-1 li a {
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #666666;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.list1-1 li a:hover {
	color: #74c0bc;
}
.list1 {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
	position: relative;
}
.list1 li {
	font-size: 15px;
	line-height: 1.428571429;
	overflow: hidden;
	margin-bottom: 33px;
}
.list1 li p {
	font-size: 12px;
	line-height: 22px;
	margin-bottom: 0;
}
.list1 li .title {
	color: #1e1e1e;
	font-size: 15px;
	margin-bottom: 11px;
}
.list1 li strong {
	font: italic 30px/20px 'Georgia';
	color: #f8941d;
	float: left;
	margin-right: 14px;
}
.list2 {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}
.list2 li {
	font-size: 15px;
	line-height: 1.428571429;
	margin-bottom: 17px;
}
.list2 li figure {
	float: left;
	margin-right: 26px;
	width: 40px;
	margin-top: 6px;
}
.list2 li p {
	margin-bottom: 0;
}
.list3 {
	margin: 0;
	padding: 0;
	list-style: none;
}
.list3 li {
	font-size: 15px;
	line-height: 1.428571429;
	margin-bottom: 10px;
	padding-bottom: 15px;
	border-bottom: 1px solid #6d6a6a;
}
.list3 li a {
	font: 27px/27px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #ffffff;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.list3 li a:hover {
	color: #de0d0f;
}
.list3 li p {
	margin-bottom: 0;
	margin-top: 7px;
}
.list3 li:last-child {
	border-bottom: none;
}
.list3 li .badge {
	padding: 0;
	margin: 0;
	float: left;
	margin-right: 19px;
	display: inline-block;
	color: #ffffff;
	font: 37px/62px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	background: #de0d0f;
	border: none;
	width: 62px;
	height: 62px;
	border-radius: 0;
	margin-top: 5px;
	text-align: center;
	position: relative;
}
.list3 li .badge em {
	position: absolute;
	top: 0;
	right: -7px;
	display: inline-block;
	width: 7px;
	height: 100%;
	/* background: url(../img/triangle2.png) right center no-repeat; */
}
.list6 {
	margin: 0;
	padding: 0;
	list-style: none;
}
.list6 li {
	font-size: 15px;
	line-height: 1.428571429;
	margin-bottom: 26px;
	overflow: hidden;
}
.list6 li p {
	margin-bottom: 0;
}
.list6 li time {
	color: #74c0bc;
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	float: left;
	margin-right: 6px;
}
.list7 {
	margin: 0;
	padding: 0;
	list-style: none;
}
.list7 li {
	font-size: 15px;
	line-height: 1.428571429;
	margin-bottom: 0;
}
.list7 li .badge {
	padding: 0;
	margin: 0;
	width: 53px;
	height: 53px;
	border-radius: 100%;
	background: #74c0bc;
	text-align: center;
	line-height: 52px;
	color: #ffffff;
	font-size: 24px;
	float: left;
	margin-right: 12px;
	margin-top: 6px;
}
.list7 li .title {
	text-transform: uppercase;
	color: #74c0bc;
	font: 300 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin-bottom: 11px;
}
.list8 {
	margin: 0;
	padding: 0;
	list-style: none;
}
.list8 li {
	font-size: 15px;
	line-height: 1.428571429;
	margin-bottom: 34px;
}
.list8 li p {
	margin-bottom: 0;
}
/*-----search-----*/
#search-404 {
	position: relative;
	margin: 33px 0 0 0;
	overflow: hidden;
	width: 298px;
}
#search-404 a {
	position: absolute;
	top: 10px;
	right: 0;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
#search-404 a:hover {
	opacity: 0.7;
	filter: alpha(opacity=70);
}
#search-404 input {
	box-shadow: none;
	border-radius: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	float: left;
	margin: 0;
	width: 267px;
	outline: none;
	border: 1px solid #e2e2e2;
	background: #fefefe;
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 18px;
	color: #666666;
	padding: 10px 14px;
	height: 41px;
	resize: none;
}
#search {
	margin: 8px 0 0 0;
	position: relative;
	float: right;
}
#search a {
	position: absolute;
	top: 0;
	right: 0;
	width: 48px;
	height: 48px;
}
#search a:hover {
	opacity: 0.7;
	filter: alpha(opacity=70);
}
#search input {
	box-shadow: none;
	border-radius: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: none;
	margin: 0;
	width: 270px;
	background: #ffffff;
	background: rgba(255, 255, 255, 0.5);
	font: 13px/48px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #ffffff;
	padding: 0 58px 0 14px;
	height: 48px;
	outline: none;
	resize: none;
	overflow: hidden;
}
/*-----map-----*/
.formBox {
	padding-bottom: 87px;
}
.form-box {
	margin-bottom: 87px;
}
.form-box h3 {
	margin-bottom: 23px;
}
.form-box p {
	color: #2d2d2d;
	font-size: 16px;
	line-height: 25px;
}
.map-box {
	margin: 0;
	width: 100%;
	line-height: 0;
	overflow: hidden;
	position: relative;
}
.map-box iframe {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 452px;
	border: none;
	line-height: 0;
	overflow: hidden;
}
.map {
	margin: 0 0 77px 0;
	width: 100%;
	line-height: 0;
	overflow: hidden;
}
.map iframe {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 470px;
	border: none;
	line-height: 0;
	overflow: hidden;
}
.info {
	margin-bottom: 38px;
}
.info h4 {
	margin-bottom: 13px;
}
.info p {
	margin-bottom: 0;
}
.info p span {
	width: 90px;
	display: inline-block;
	color: #666666;
}
.info p a {
	color: #ea4959;
}
.info p a:hover {
	color: #666666;
}
.mail {
	color: #74c0bc;
	font: 15px 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-decoration: underline;
	-webkit-transition: all 0.25s;
	transition: all 0.25s;
}
.mail:hover {
	color: #666666;
	text-decoration: underline;
}
.priv-box {
	margin-bottom: 87px;
}
/*------------------------------------------------------media queries---------------------------------------------------------*/
@media (min-width: 1220px) {
	.mz-3{
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.responsivePadding{
		padding-top:0;
	}
	.responsiveWidth{
		width:40%;
	}
	.mobile_menu{
		display: none;
	}
.desktop_menu{
	display: block;
}
 .container {
 padding: 0;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 992px) and (max-width: 1219px) {
	.mz-3{
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.responsivePadding{
		padding-top: 0;
	}
	.responsiveWidth{
		width:40%;
	}
	.mobile_menu{
		display: none;
	}
	.desktop_menu{
		display: block;
	}
 h3 {
 font-size: 30px;
 line-height: 35px;
}
 .thumb-pad1 {
 width: 312px;
}
 .testimonials-box .col-lg-4 {
 margin-bottom: 30px;
}
 .errorBox .title {
 font-size: 80px;
 line-height: 80px;
}
 .errorBox .description {
 font-size: 55px;
 line-height: 55px;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (min-width: 768px) and (max-width: 991px) {
	.mz-3{
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.responsivePadding{
		padding-top: 0;
	}
	.responsiveWidth{
		width:40%;
	}
	.mobile_menu{
		display: none;
	}
	.desktop_menu{
		display: block;
	}
 header {
 text-align: center;
}
 h1.navbar-brand_ {
 float: none;
}
 h3 {
 font-size: 30px;
 line-height: 35px;
}
 nav.tm_navbar {
 float: none;
}
 .thumb-pad1 {
 width: 49%;
}
 .thumb-pad1 .thumbnail .caption > div {
 float: none;
}
 .thumb-pad1 .thumbnail .caption .price {
 float: none;
 display: block;
}
 .testimonials-box .col-lg-4 {
 margin-bottom: 30px;
}
 .errorBox .title {
 font-size: 80px;
 line-height: 80px;
}
 .errorBox .description {
 font-size: 55px;
 line-height: 55px;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
	.mz-3{
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.responsivePadding{
		padding-top: 0;
	}
	.responsiveWidth{
		width:40%;
	}
	.mobile_menu{
		display: none;
	}
	.desktop_menu{
		display: block;
	}
 header {
 text-align: center;
}
 h1.navbar-brand_ {
 float: none;
}
 h3 {
 font-size: 30px;
 line-height: 35px;
}
 nav.tm_navbar {
 float: none;
}
 nav.tm_navbar .nav {
 display: none;
}
 nav.tm_navbar {
 width: 100%;
}
 .select-menu {
 display: inline-block !important;
 padding-top: 4px;
 padding-right: 3px;
 padding-bottom: 4px;
 width: 100%;
 border: 1px solid #303030;
 background: #303030;
 color: #ffffff;
 cursor: pointer;
 height: 30px;
 font-size: 12px;
 vertical-align: middle;
}
 .map iframe {
 height: 300px;
}
 .privacyBox {
 margin-bottom: 50px;
}
 .thumb-pad1 {
 width: 100%;
 margin-left: 0;
}
 .thumb-pad1 .thumbnail .caption > div {
 float: none;
}
 .thumb-pad1 .thumbnail .caption .price {
 float: none;
 display: block;
}
 .testimonials-box .col-lg-4 {
 margin-bottom: 30px;
}
 footer {
 padding: 20px 0;
}
 .thumb-pad2 figure {
 margin: 0 23px 0 0;
 float: left;
}
 .gourmet-box .col-lg-5,  .gourmet-box .col-lg-7 {
 margin-bottom: 35px;
}
 .errorBox .title {
 font-size: 80px;
 line-height: 80px;
}
 .errorBox .description {
 font-size: 55px;
 line-height: 55px;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 626px) {
	.mz-3{
		margin-left: 1rem !important;
		margin-right: 1rem !important;
	}
	.responsivePadding{
		padding-top: 0;
	}
	.responsiveWidth{
		width:40%;
	}
	.mobile_menu{
		display: none;
	}
	.desktop_menu{
		display: block;
	}
 .thumb-pad2 figure {
 margin: 0 0 23px 0;
 float: none;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 480px) {
	.mz-3{
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	
	.responsivePadding{
		padding-top: 50%;
	}
	.responsiveWidth{
		width:100%;
	}
	.mobile_menu{
		display: block;
	}
	.desktop_menu{
		display: none;
	}

 h1.navbar-brand_ a img {
 width: 100%;
}
 .col-xs-4 {
 width: 100%;
 float: none;
}
 .col-xs-6 {
 width: 100%;
 float: none;
}
 .thumb-pad5 .thumbnail .caption {
 padding: 0;
 overflow: visible;
}
 .dishes-box .col-xs-6 {
 width: 50%;
 float: left;
}
 .errorBox .title {
 font-size: 40px;
 line-height: 40px;
}
 .errorBox .description {
 font-size: 30px;
 line-height: 35px;
}
 #search-404 {
 width: 283px;
}
 #search-404 input {
 width: 247px;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 320px) {
	.mz-3{
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.responsivePadding{
		padding-top: 50%;
	}
	.responsiveWidth{
		width:100%;
	}
	.desktop_menu{
		display: none;
	}
.mobile_menu{
	display: block;
}
 .dishes-box .col-xs-6 {
 width: 100%;
 float: none;
}
}
/*---------------------------------------------------------------------------------------------------------------------------*/
.margBot {
	margin-bottom: 0;
}
.padBot {
	padding-bottom: 0;
}
/*Core variables and mixins*/
/*-------------------------------------------------*/
/*-------------------------------------------------*/
